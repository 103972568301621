global.fetch = require('node-fetch')
var AmazonCognitoIdentity = require('amazon-cognito-identity-js');

var poolData = {
    UserPoolId : 'us-east-1_xTD5dbTyN',
    ClientId : '6nkearqm3i68abseilq8oa4sh3'
};

var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

export function checkSession(checkCallback) {
    var cognitoUser = userPool.getCurrentUser();
    if (cognitoUser != null) {
	cognitoUser.getSession(function(err, session) {
	    if (err) {
		checkCallback(false);
	    } else {
		checkCallback(session.isValid());
	    }
	});
	return true;
    }
    return false;
}

export function getToken() {
    const clientId = poolData.ClientId
    const usernameKey = `CognitoIdentityServiceProvider.${clientId}.LastAuthUser`
    const username = localStorage[usernameKey]
    const idTokenKey = `CognitoIdentityServiceProvider.${clientId}.${username}.idToken`
    const idToken = localStorage[idTokenKey]
    return idToken;
}

export function loginUser(username, password, onSuccess) {
    var userData = {
	Username : username,
	Pool : userPool
    }

    var authenticationData = {
	Username : username,
	Password: password,
    }

    var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData)

    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
	onSuccess: function (result) {
	    onSuccess();
	},
	onFailure: function(err) {
	    console.log(err);
	},
	mfaRequired: function(codeDeliveryDetails) {
	    var verificationCode = prompt('Please input verification code', '');
	    cognitoUser.sendMFACode(verificationCode, this);
	},
	newPasswordRequired: function(userAttributes, requiredAttributes) {
	    cognitoUser.completeNewPasswordChallenge('', {}, this);
	}
    });
}
